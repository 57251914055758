export class ContactRequestModel {
    public Name = '';
    public Email = '';
    public Number = '';
    public Subject = '';
    public Message = '';

    constructor(init: Partial<ContactRequestModel> = {}) {
        Object.assign(this, init);
    }
}
