import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes: RouteRecordRaw[] = [
    {
        path: "/",
        name: "Home",
        component: HomeView,
    },
    {
        path: "/about",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ "../views/AboutContainerView.vue"),
        children: [
            {
                path: '',
                name: 'About Us',
                component: () => import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
            },
            {
                path: 'team',
                name: 'Our Team',
                component: () => import(/* webpackChunkName: "team" */ "../views/TeamView.vue"),
            },
            {
                path: 'expertise',
                name: 'Our Expertise',
                component: () => import(/* webpackChunkName: "expertise" */ "../views/ExpertiseView.vue"),
            },
        ]
    },
    {
        path: "/expertise",
        name: "Our Expertise",
        component: () => import(/* webpackChunkName: "expertise" */'../views/ExpertiseView.vue'),
    },
    {
        path: "/services",
        name: "Services",
        component: () => import(/* webpackChunkName: "services" */'../views/ServicesView.vue'),
    },
    {
        path: "/contact",
        name: "Contact Us",
        component: () => import(/* webpackChunkName: "contact" */'../views/ContactView.vue'),
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
