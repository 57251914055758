export class ServiceModel {
    public Title = '';
    public IconUrl = '';
    public Description = '';
    public RouteUrl = '';

    constructor(init: Partial<ServiceModel> = {}) {
        Object.assign(this, init);
    }
}
