import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import "bootstrap/scss/bootstrap.scss";
import "./assets/css/plugins/all.min.css";
import "./assets/scss/style.scss";
import "./assets/css/plugins/flaticon.css";

import "bootstrap";

const app = createApp(App).use(store).use(router);
app.config.globalProperties.jquery = jQuery;
app.mount("#app");
